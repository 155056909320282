const hostUrl = new URL((document.currentScript as HTMLScriptElement).src).origin;

// TODO: Need to rename window.phoenixConfig to a more generic name like window.truAppConfig and place all config
//  in there. Only have phoenix/shell setup all config for both echo-ui and phoenix in this file. Remove as much
//  environment vars from EnvironmentPlugin defined in echo-ui as possible. For example don't remove TEMPLATE_CACHE_BUSTING
window.ECHO_UI_PIPELINE_URL = process.env.REACT_APP_ECHO_UI_PIPELINE_URL as string;
window.ECHO_UI_SHA = process.env.REACT_APP_ECHO_UI_SHA as string;
window.phoenixConfig = {
  AppVersionEchoUi: process.env.REACT_APP_ECHO_UI_SHA as string,
  AppVersionPhoenix: process.env.REACT_APP_CURRENT_REV_SHA as string,
  apiUrl: process.env.REACT_APP_API_URL as string,
  bugsnagApiKey: process.env.REACT_APP_BUGSNAG_API as string,
  bugsnagApiKeyEchoUi: process.env.REACT_APP_BUGSNAG_API_ECHO_UI as string,
  bugsnagReleaseStage: process.env.REACT_BUGSNAG_RELEASE_STAGE as string,
  echoUiUrl: `${hostUrl}/echo-ui`,
  env: process.env.REACT_APP_ENV as Environments,
  memberSvcOnPremToken: process.env.REACT_APP_MEMBER_SERVICE_ON_PREM_TOKEN as string,
  memberSvcUrl: process.env.REACT_APP_MEMBER_SERVICE_URL as string,
  muiProLicense: process.env.REACT_APP_MUI_PRO_LICENSE as string,
  phoenixUrl: `${hostUrl}/phoenix`,
  pushJsKey: process.env.REACT_APP_PUSHER_KEY as string,
  pusherJsCluster: process.env.REACT_APP_PUSHER_CLUSTER as string,
};
window.PHOENIX_PIPELINE_URL = process.env.REACT_APP_PHOENIX_PIPELINE_URL as string;
window.PHOENIX_SHA = process.env.REACT_APP_CURRENT_REV_SHA as string;

import("./bootstrap").catch(e => {
  alert("Oops, something went wrong! Please reload the page.");
  throw e;
});

export {};
